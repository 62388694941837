<template>
  <div v-loading="modalLoading">
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <!-- <el-col :span="8">
          <div class="form-group">
            <el-form-item label="Parent Entity">
              <el-select
                v-model="field.global_variable_entity_parent"
                @change="onSelectParentData"
                no-data-text="No data available"
                filterable
                default-first-option
              >
                <el-option
                  v-for="(item,index) of getEntityVariables()"
                  :value="item.key"
                  :key="index"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col> -->
        <el-col :lg="12" :md="12" :sm="12" :xs="12">
          <div class="form-group">
            <el-form-item label="Entities">
              <el-select
                v-model="field.relationship_key"
                @change="setEntityFields"
                no-data-text="No Entities available"
                filterable
                default-first-option
              >
                <el-option
                  v-for="(entity, index) of filteredEntities"
                  :value="entity.key"
                  :key="index"
                  :label="entity.label"
                >
                  <span style="float: left">{{ entity.label }}</span>
                  <span
                    style="float: right; color: #8492a6; font-size: 13px"
                    v-if="entity.is_template_variable"
                    >Template variable</span
                  >
                </el-option>
              </el-select>
            </el-form-item>
            <el-checkbox
              v-if="field.inputType == 'ENTITY'"
              :disabled="checkEntityVariable"
              v-model="field.allow_multiple"
              >Allow multiple data</el-checkbox
            >
          </div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="12" :xs="12">
          <div class="form-group d-flex">
            <el-form-item label="Entity Variable">
              <el-select
                :loading="companyTemplateLoading"
                v-model="field.global_variable_entity_field"
                :no-data-text="'No variables available'"
                filterable
                default-first-option
                @change="setFieldData"
              >
                <div
                  v-for="(field, index) of selectedEntityFields"
                  :key="index"
                >
                  <template v-if="field.input_type == 'ACTION_BUTTON'">
                    <el-option-group :label="field.label">
                      <div
                        v-for="(tempField, tempIndex) of getActionButtonOptions(
                          field
                        )"
                        :key="tempIndex"
                      >
                        <el-option
                          :value="tempField._id"
                          :label="tempField.label"
                        >
                          <span style="float: left; margin-left: 12px"
                            >{{ tempField.label }}
                          </span>
                          <span
                            style="
                              float: right;
                              color: #8492a6;
                              font-size: 13px;
                            "
                            v-if="tempField.is_relational"
                            >Relation</span
                          >
                        </el-option>
                      </div>
                    </el-option-group>
                  </template>
                  <template v-else>
                    <el-option :value="field.key" :label="field.label">
                      <span style="float: left">{{ field.label }}</span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                        v-if="field.is_relational"
                        >Relation</span
                      >
                    </el-option>
                  </template>
                </div>
              </el-select>
            </el-form-item>
            <el-form-item
              label="Variable operation"
              class="ml-1"
              v-if="checkIsCountField"
            >
              <el-select
                placeholder="Field operation"
                v-model="field.variable_action"
                filterable
              >
                <el-option
                  v-for="(op, i) in variableTypes"
                  :key="i + 'op' + op"
                  :label="op"
                  :value="op"
                >
                </el-option>
                <el-option
                  v-if="checkIsResourceField"
                  label="SELECTED RESOURCE AVERAGE"
                  value="RESOURCE_AVERAGE"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="Payment Variable"
              class="ml-1"
              v-if="checkIsPayField"
            >
              <el-select
                placeholder="Fields List"
                v-model="field.global_variable_pay_field"
                filterable
                @change="setPayFieldData"
              >
                <el-option
                  v-for="(payObjField, index) of paymentFields"
                  :value="payObjField.key"
                  :key="index"
                  :label="payObjField.label"
                >
                  <span style="float: left">{{ payObjField.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <is-field-required :field="field" class="field-required" />
        </el-col>
        <!-- <el-col :lg="8" :md="8" :sm="12" :xs="12">
          <el-form-item label="Select type">
            <el-select
              v-model="field.global_variable_entity_select_type"
              :disabled="!field.global_variable_entity_field"
              @change="setFieldType"
            >
              <el-option
                v-for="(item, index) of selectTypes"
                :key="index"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :lg="12">
          <!-- <el-checkbox v-model="field.is_field_readonly"
            >is field readonly</el-checkbox
          > -->
          Is this field?
          <el-radio-group v-model="field.field_assignable" size="small">
            <el-radio label="read_only">Read only</el-radio>
            <el-radio label="editable">Editable</el-radio>
            <el-radio
              label="update_parent"
              v-if="!field.relationship_entity_id && !checkIsCountField"
              >Update parent</el-radio
            >
          </el-radio-group>
          <div v-if="field.field_assignable != 'read_only'">
            <el-checkbox v-model="field.overwrite_on_change"
              >overwrite on change</el-checkbox
            >
          </div>
          <div
            v-if="
              field.field_assignable == 'update_parent' &&
              field.inputType == 'DATA_TABLE' &&
              selectedEntityFieldData &&
              selectedEntityFieldData.data_table_columns &&
              selectedEntityFieldData.data_table_columns.length
            "
            class="d-flex mt-1 mb-1"
          >
            <el-checkbox v-model="field.alow_data_table_add"
              >Allow add</el-checkbox
            >
            <el-checkbox v-model="field.alow_data_table_edit"
              >Allow delete</el-checkbox
            >
          </div>
        </el-col>
        <el-col :lg="6">
          <el-link
            :underline="false"
            type="primary"
            v-if="field.inputType == 'DATA_TABLE'"
            @click="openFormRulesModal"
          >
            Form rules
          </el-link>
        </el-col>
        <el-col :lg="6" v-if="checkDisplayTableFilters">
          <el-link
            :underline="false"
            type="primary"
            v-if="field.inputType == 'DATA_TABLE'"
            @click="openDataTableFiltersModal"
          >
            Get only filtered data
          </el-link>
        </el-col>
      </el-row>
      <el-row
        v-if="
          field.field_assignable == 'update_parent' &&
          field.inputType == 'DATA_TABLE' &&
          selectedEntityFieldData &&
          selectedEntityFieldData.data_table_columns &&
          selectedEntityFieldData.data_table_columns.length
        "
      >
        <el-col :span="20">
          <div class="config-box">
            <p>Field</p>
            <p>Options</p>
            <!-- <p>Read only</p>
            <p>Editable</p>
            <p>Update parent</p> -->
          </div>
          <!-- <el-scrollbar wrap-style="max-height: 200px;" ref="configureScroll"> -->
          <div
            v-for="(fl, i) in filterUpdateFields(
              selectedEntityFieldData.data_table_columns
            )"
            :key="i"
            class="config-radio"
          >
            <p>{{ fl.label }}</p>
            <el-radio-group class="ml-3 d-flex" v-model="fl.field_assignable">
              <el-radio label="read_only">Read only</el-radio>
              <el-radio class="ml-6" label="editable">Editable</el-radio>
              <el-radio class="ml-7" label="update_parent"
                >Update parent</el-radio
              >
            </el-radio-group>
            <div>
              <el-select
                v-if="
                  (fl.field_assignable == 'update_parent' ||
                    fl.show_in_split) &&
                  field.alow_data_table_add &&
                  (fl.inputType == 'NUMBER' || fl.inputType == 'CURRENCY')
                "
                v-model="fl.field_operation"
                placeholder="Operation"
                style="margin-left: 350px; width: 100%"
                clearable
                filterable
                size="mini"
              >
                <el-option value="SUM" label="Sum"> </el-option>
                <el-option value="AVERAGE" label="Average"> </el-option>
              </el-select>
            </div>
            <div>
              <el-checkbox
                v-if="fl.field_assignable != 'read_only'"
                v-model="fl.show_in_split"
              >
                Show in split data
              </el-checkbox>
              <el-checkbox
                v-if="fl.field_assignable != 'read_only' && !fl.show_in_split"
                v-model="fl.editable_detailed_view"
              >
                Editable in popup
              </el-checkbox>
            </div>
          </div>
          <!-- </el-scrollbar> -->
        </el-col>
      </el-row>
      <el-checkbox
        v-if="field.inputType == 'ENTITY'"
        class="mt-1"
        v-model="field.auto_select_one_data"
        >Auto select if entity has only 1 data</el-checkbox
      >
      <el-checkbox
        v-if="field.inputType == 'ENTITY'"
        class="mt-1"
        v-model="field.show_all_option"
        >Show ALL option</el-checkbox
      >
      <EntityFilters
        :field="field"
        :fieldsData="fieldsData"
        v-if="field.inputType == 'ENTITY'"
      ></EntityFilters>
    </el-form>
    <el-dialog
      :visible.sync="formRulesModal"
      title="Form rules"
      width="40%"
      :before-close="appendRules"
    >
      <template-form-rules-widget
        :allFields="
          selectedEntityFieldData && selectedEntityFieldData.data_table_columns
            ? selectedEntityFieldData.data_table_columns
            : []
        "
        :templateRules="fieldRules"
        @form-rule-added="updateFormRules"
        @form-rule-delete="deleteFormRules"
      >
      </template-form-rules-widget>
    </el-dialog>
    <el-dialog
      :visible.sync="addDataTableStepFilters"
      title="Add filters"
      :before-close="resetDataTableFilters"
      :width="getIsMobile ? '100%' : '50%'"
    >
      <div>
        <el-row>
          <div>
            <el-select
              v-model="dataTablefiltersQuery"
              placeholder="Query type"
              size="mini"
            >
              <el-option
                v-for="op in [
                  { name: 'All', value: 'AND' },
                  { name: 'Any one', value: 'OR' },
                ]"
                :key="op.name + '_query_type'"
                :label="op.name"
                :value="op.value"
              >
              </el-option>
            </el-select>
            conditions need to be satisfied
          </div>
          <el-link
            type="primary"
            icon="el-icon-plus"
            class="float-right"
            :underline="false"
            @click="addFilter"
            >Add filter</el-link
          >
        </el-row>
        <el-row
          v-for="(filter, i) in dataTablefilters"
          :key="i + '_filters_field'"
          :gutter="10"
          class="mt-1"
        >
          <el-col :span="6">
            <el-select
              placeholder="Field"
              v-model="filter.selectField"
              @change="
                (changedValue) =>
                  checkCurrentFieldData(
                    changedValue,
                    filter,
                    allFieldsWithDataTable
                  )
              "
            >
              <el-option
                v-for="(op, i) in allFieldsWithDataTable"
                :key="i + '_filter_field'"
                :value="op.key"
                :label="op.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="filter.operator"
              :disabled="!filter.selectField"
              placeholder="Operator"
            >
              <el-option
                v-for="(operator, index) of getCurrentFieldOperators(filter)"
                :key="index + '_operator'"
                :label="operator.title"
                :value="operator.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col
            :span="5"
            v-if="filter.operator != 'exists' && filter.operator != 'notexists'"
          >
            <el-select
              v-model="filter.data_source"
              placeholder="Data source"
              :disabled="!filter.operator"
            >
              <el-option
                v-for="(source, index) of allowedDataSources(filter)"
                :key="index"
                :label="source"
                :value="source"
              ></el-option>
            </el-select>
          </el-col>
          <el-col
            :span="6"
            v-if="filter.operator != 'exists' && filter.operator != 'notexists'"
          >
            <template v-if="filter.data_source == 'TEMPLATE'">
              <el-select
                placeholder="Resource field"
                v-model="filter.value_field"
                clearable
              >
                <el-option
                  v-for="(option, index) of getRelatedFields(filter)"
                  :value="option.key"
                  :label="option.label"
                  :key="index"
                ></el-option>
              </el-select>
            </template>
            <template v-else>
              <el-select
                v-if="
                  filter.inputType == 'SELECT' &&
                  filter.data_source == 'OPTIONS'
                "
                placeholder="Resource field"
                v-model="filter.value"
                clearable
                multiple
              >
                <el-option
                  v-for="(option, index) of getSelectOptions(
                    filter,
                    allFieldsWithDataTable
                  )"
                  :value="option"
                  :label="option"
                  :key="index + '_options'"
                ></el-option>
              </el-select>
              <el-input
                v-model="filter.value"
                v-else-if="filter.data_type == 'STRING'"
                :disabled="!filter.data_source"
              ></el-input>
              <el-input-number
                v-else-if="filter.data_type == 'NUMBER'"
                v-model="filter.value"
                :controls="false"
                placeholder="value"
                :disabled="!filter.data_source"
              ></el-input-number>
            </template>
          </el-col>
          <el-col :span="2">
            <el-link
              class="mt-1 ml-2"
              type="danger"
              :underline="false"
              @click="removeFilter(i)"
            >
              <i class="el-icon-delete"></i>
            </el-link>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetDataTableFilters">Close</el-button>
        <el-button
          type="primary"
          :disabled="checkFilterDisable"
          @click="saveFilters"
          >Save</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
// import FieldFilledBy from "./FieldFilledBy";
// import Placeholder from "./Placeholder";
import { mapGetters } from "vuex";

import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import AutomationHelper from "@/mixins/AutomationHelper";
import templateBuilderHelper from "@/mixins/templateBuilderHelper";
export default {
  name: "templates-formComponents-EntityVariable",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    EntityFilters: () => import("./EntityFilters"),
    TemplateFormRulesWidget: () =>
      import("../../widgets/TemplateFormRulesWidget.vue"),
    IsFieldRequired: () => import("./IsFieldRequired"),
  },
  mixins: [entityRelationshipMixin, AutomationHelper, templateBuilderHelper],
  computed: {
    ...mapGetters("userTypes", ["getUserTypes"]),
    // ...mapGetters("companyEntities", ["getAllCompanyEntitiesData"]),
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    ...mapGetters("entities", ["getAllEntities", "getEntityDataByEntityId"]),
    ...mapGetters("entityRelationships", ["getEntityRelationships"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    getValidUserTypes() {
      return (this.getUserTypes.data || []).filter(
        (userType) => userType.name != "ADMIN"
      );
    },
    getValidEntities() {
      return (this.getAllEntities.data || []).filter(
        (entity) => entity.status == "ACTIVE"
      );
    },
    filteredEntities() {
      return this.entities.filter((entity) => !entity.show_count);
    },
    checkEntityVariable() {
      if (this.fieldsData && this.fieldsData.length > 0) {
        let variables = this.fieldsData.filter(
          (e) =>
            e.relationship_key == this.field.key && e.inputType != "DATA_TABLE"
        );
        return variables.length ? true : false;
      }
      return false;
    },
    checkIsResourceField() {
      if (
        this.field?.relationship_key &&
        this.field?.global_variable_entity_field
      ) {
        let parent = (this.fieldsData || []).find(
          (e) => e.key == this.field.relationship_key
        );
        if (parent?.filters) {
          let ex = parent.filters.find((e) => {
            if (
              e?.field == "RESOURCE_MANAGEMENT" &&
              e?.resource_count != "ALL_DATA"
            ) {
              return true;
            }
          });
          return ex &&
            ex.resource_source == "TEMPLATE" &&
            ex.resource_value_field == this.field.global_variable_entity_field
            ? true
            : false;
        }
        return false;
      }
      return false;
    },
    checkDisplayTableFilters() {
      if (
        this.field?.inputType == "DATA_TABLE" &&
        this.field?.relationship_key
      ) {
        let parent = (this.fieldsData || []).find(
          (e) => e.key == this.field.relationship_key
        );
        if (parent?.detailed_view && parent?.allow_multiple) {
          return true;
        }
      }
      return false;
    },
    checkIsCountField() {
      if (
        this.field?.relationship_key &&
        this.field?.global_variable_entity_field
      ) {
        let parent = (this.fieldsData || []).find(
          (e) => e.key == this.field.relationship_key
        );
        if (
          parent?.allow_multiple &&
          (["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION"].indexOf(
            this.field.inputType
          ) != -1 ||
            (this.field.inputType == "FORMULA" &&
              ["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION"].indexOf(
                this.field.result_type
              ) != -1))
        ) {
          return true;
        }
      }
      return false;
    },
    checkIsPayField() {
      if (this.field.global_variable_entity_field) {
        let selectedEntityField = this.selectedEntityFields.find(
          (field) => field.key == this.field.global_variable_entity_field
        );
        if (
          selectedEntityField &&
          selectedEntityField.inputType == "PAY_BUTTON"
        ) {
          return true;
        }

        return false;
      }
      return false;
    },
  },
  props: ["field", "fieldsData", "index"],
  data() {
    return {
      modalLoading: false,
      validations: [],
      globalVariableTypes: ["USERS", "ENTITY"],
      selectTypes: [
        { label: "Single", value: "single" },
        { label: "Multiple", value: "multiple" },
      ],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1",
        },
        {
          title: "Folder 2",
          _id: "2",
        },
        {
          title: "Folder 3",
          _id: "3",
        },
      ],
      selectedEntityFields: [],
      selectedEntityFieldData: {},
      selectedEntity: {},
      entities: [],
      parent_key: "",
      actionFieldData: [],
      entitiesData: [],
      relationshipsData: [],
      contentFields: [
        "IMAGE",
        "HEADING",
        "PARAGRAPH",
        "VIDEO",
        "SINGLE_LINE_CONTENT",
        "HORIZONTAL_LINE",
        "DIVISION",
        "AGGREGATE_FUNCTION",
        "HTML_CONTENT",
        "ACTION_BUTTON",
        "HTML_CONTENT",
        "MASKED",
        "HYPER_LINK",
        "DATA_TABLE",
        "ENTITY_VARIABLE",
        "FORMULA",
      ],
      variableTypes: ["SUM", "AVERAGE", "HIGHEST", "LOWEST"],
      // allowedTypes: ["DATA_TABLE"],
      paymentFields: [
        {
          label: "Transaction Id",
          key: "txn_id",
          input_type: "SINGLE_LINE_TEXT",
        },
        { label: "Gateway", key: "gateway", input_type: "SINGLE_LINE_TEXT" },
        { label: "Name", key: "name", input_type: "SINGLE_LINE_TEXT" },
        { label: "Email", key: "email", input_type: "SINGLE_LINE_TEXT" },
        {
          label: "Phone Number",
          key: "phoneNumber",
          input_type: "PHONE_COUNTRY_CODE",
        },
        {
          label: "Payment Type",
          key: "paymentType",
          input_type: "SINGLE_LINE_TEXT",
        },
        { label: "Method", key: "method", input_type: "SINGLE_LINE_TEXT" },
        { label: "Currency", key: "currency", input_type: "SINGLE_LINE_TEXT" },
        { label: "Amount", key: "amount", input_type: "CURRENCY" },
        { label: "Payment Date", key: "paymentDate", input_type: "DATE" },
        { label: "Status", key: "status", input_type: "SINGLE_LINE_TEXT" },
        {
          label: "Payment Session",
          key: "paymentSession",
          input_type: "SINGLE_LINE_TEXT",
        },
        {
          label: "Amount Refunded",
          key: "amountRefunded",
          input_type: "CURRENCY",
        },
        {
          label: "Description",
          key: "description",
          input_type: "SINGLE_LINE_TEXT",
        },
        { label: "Refund Date", key: "refundDate", input_type: "DATE" },
      ],
      allowedTypes: ["DATA_TABLE", "NUMBER", "CURRENCY", "AGGREGATE_FUNCTION"],
      formRulesModal: false,
      fieldRules: [],
      addDataTableStepFilters: false,
      dataTablefilters: [],
      dataTablefiltersQuery: "AND",
      allCompanyTemplatesData: [],
      companyTemplateLoading: false,
    };
  },
  async mounted() {
    // this.field.global_variable_type = "USERS";
    this.modalLoading = true;
    this.field.validations.type = "OBJECT_ID";
    let [entitiesData, allCompanyTemplatesData] = await Promise.all([
      this.fetchAllEntities(),
      this.fetchAllTemplates(),
    ]);
    this.entitiesData = [...entitiesData];
    this.allCompanyTemplatesData = [...allCompanyTemplatesData];
    await this.addSelfTemplateFields();
    setTimeout(async () => {
      this.checkData();
    }, 100);
    if (!this.field.field_assignable) {
      this.$set(this.field, "field_assignable", "read_only");
    }
    this.selectedEntityFieldData = {};
    if (this.field?.global_variable_entity_parent) {
      if (
        this.field.data_table_columns &&
        this.field.inputType == "DATA_TABLE"
      ) {
        this.selectedEntityFieldData = { ...this.field };
      }
    }
    this.modalLoading = false;
  },
  methods: {
    openDataTableFiltersModal() {
      this.dataTablefilters = [];
      this.dataTablefiltersQuery = "AND";
      this.dataTablefilters = this.field.data_table_filters || [];
      this.dataTablefiltersQuery = this.field.data_table_filter_query || "AND";
      let allowedFields = [
        "NUMBER",
        "CURRENCY",
        "FORMULA",
        "AGGREGATE_FUNCTION",
        "SELECT",
      ];
      this.allFieldsWithDataTable = (this.field.data_table_columns || [])
        .filter((e) => e.key && allowedFields.indexOf(e.inputType) !== -1)
        .map((dt) => {
          let e = JSON.parse(JSON.stringify(dt));
          e.data_type = this.getTypeFromInputType(e.result_type);
          return e;
        });
      this.addDataTableStepFilters = true;
    },
    saveFilters() {
      this.$set(this.field, "data_table_filters", this.dataTablefilters);
      this.$set(
        this.field,
        "data_table_filter_query",
        this.dataTablefiltersQuery
      );
      this.resetDataTableFilters();
    },
    appendRules() {
      this.field["rules"] = [...this.fieldRules];
      // this.$emit("data-table-rules-updates", this.fieldRules, this.index);
      this.fieldRules = [];
      this.formRulesModal = false;
    },
    deleteFormRules(index) {
      this.fieldRules.splice(index.index, 1);
    },
    updateFormRules(data) {
      if (data.index == -1) {
        this.fieldRules.push(data);
      } else {
        this.fieldRules[data.index] = { ...data };
      }
    },
    openFormRulesModal() {
      this.fieldRules = [...this.field.rules];
      this.formRulesModal = true;
    },
    getNumberFields(fields) {
      let allowedTypes = ["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION", "FILE"];
      return fields.filter((e) => {
        if (
          allowedTypes.indexOf(e?.inputType) !== -1 ||
          e?.inputType == "FILE" || // Include fields with input_type equal to "FILE"
          e?.inputType != "FORMULA" ||
          (e?.inputType == "FORMULA" &&
            allowedTypes.indexOf(e?.result_type) !== -1)
        ) {
          return true;
        }
      });
    },

    //set key value based on the parent field key
    setPayFieldData(fieldId) {
      let selectedEntityField = {};
      selectedEntityField = this.paymentFields.find(
        (field) => field.key == fieldId
      );
      if (this.field.global_variable_entity_field) {
        let splitValues = this.field.global_variable_entity_field.split("#");

        this.field.key = splitValues[1] + "_" + fieldId;
        this.field.inputType = selectedEntityField.input_type;
      }
    },

    filterUpdateFields(fields) {
      return fields.filter((e) => {
        return this.contentFields.indexOf(e.input_type) == -1;
      });
    },
    getActionButtonOptions(field) {
      return field?.template_fields?.sections[0]["fields"] || [];
    },
    async fetchUserTypes() {
      try {
        await this.$store.dispatch("userTypes/fetchUserTypes", {
          get_all: true,
        });
      } catch (error) {
        console.log("fetchUserTypes", error);
      }
    },
    addTemplateEntityField(fields) {
      fields.forEach((element) => {
        if (element.inputType == "ENTITY" && element.key != this.field.key) {
          element["is_template_variable"] = true;
          this.entities.push(element);
        }
      });
    },

    addSelfTemplateFields() {
      if (this.fieldsData && this.fieldsData.length) {
        // WE NEED TO CHECK TABLE FIELD OR NOT
        this.addTemplateEntityField(this.fieldsData);

        // if (this.field.data_table_field_index > -1) {
        //   // we need to inlcude table entity fields here
        //   this.fieldsData
        //     .filter((field) => field.input_type == "DATA_TABLE")
        //     .forEach((field) =>
        //       this.addTemplateEntityField(field.data_table_columns)
        //     );
        // }
      }
    },

    getEntityVariables() {
      let fields = [];
      this.fieldsData.forEach((element) => {
        if (
          element.input_type == "GLOBAL_VARIABLE" ||
          element.input_type == "ENTITY_VARIABLE"
        ) {
          fields.push(element);
        }
      });
      return fields;
    },

    async setEntityFields(key, isUpdate = false) {
      let selectedEntity = {};
      let templateFieldKey;

      if (key) {
        selectedEntity = this.entities.find((entity) => entity.key == key);
        templateFieldKey = selectedEntity.key;
      }
      this.parent_key = templateFieldKey;

      this.selectedEntityFields = [];
      console.log("isUpdate", isUpdate);
      this.selectedEntity = {};
      if (selectedEntity) {
        this.selectedEntity = this.entitiesData.find(
          (entity) => entity._id == selectedEntity.entity_id
        );
      }
      if (
        this.selectedEntity &&
        this.selectedEntity.templates &&
        this.allCompanyTemplatesData &&
        this.allCompanyTemplatesData.length
      ) {
        let templates = this.selectedEntity.templates
          .filter((e) => !e.is_repeatable)
          .flatMap((e) => e.template_id);
        const selectedTemplate = this.allCompanyTemplatesData.filter(
          (e) => templates.indexOf(e._id) != -1
        );
        let templateId =
          this.$route.query && this.$route.params.template_id
            ? this.$route.params.template_id
            : this.field.template_id;
        selectedTemplate.forEach((e) => {
          let temp = this.selectedEntity.templates.find(
            (t) => t.template_id == e._id
          );
          let fields = this.applyCustomizationOnFields(
            e?.sections?.[0]?.fields || [],
            temp?.customization || {}
          );
          this.selectedEntityFields = [
            ...this.selectedEntityFields,
            ...this.filterReadOnlyFields(this.mapTemplatesFields(fields, e)),
          ];
        });
        let parent = (this.fieldsData || []).find(
          (e) => e.key == this.field.relationship_key
        );
        //remove self field & check parent is multiple
        this.selectedEntityFields = this.selectedEntityFields.filter(
          (field) => {
            const isExcluded =
              (templateId == field.template_id &&
                templateId + "#" + this.field.relationship_key === field.key) ||
              (parent?.allow_multiple &&
                ((this.allowedTypes.indexOf(field.inputType) == -1 &&
                  field.input_type != "FORMULA") ||
                  (field.input_type == "FORMULA" &&
                    this.allowedTypes.indexOf(field.result_type) == -1)));

            return !isExcluded || field.inputType === "FILE";
          }
        );
        console.log("this.selectedEntityFields", this.selectedEntityFields);
        // we have to fetch related to fields
        await this.fetchEntityRelationships(this.selectedEntity._id);

        this.relationshipsData.forEach((e) => {
          let field = {
            action_button_repeating_template_relationships: [],
            label: this.selectedEntity.name + "->" + e.relationship_title,
            key: e.relationship_id + "~" + e.slug,
            input_type: "ENTITY",
            inputType: "ENTITY",
            is_relational: true,
            content: "",
            selected_fields: [],
            formula: "",
            function_type: "",
            validations: {
              required: false,
              type: "OBJECT_ID",
              data_type: "OBJECT_ID",
            },
            defaultCountry: "US",
            countryCodeSelector: true,
            countryDisable: false,
            styles: {
              font: { name: "Helvetica", size: 16, style: 0, color: "#000000" },
            },
            properties: {
              hideLabel: false,
              prefix: false,
              _id: "634d6ed8e51da443f3a2b7f9",
              is_unique: false,
              personal_data: false,
              personal_data_type: "",
              is_encrypted: false,
            },
            decimals: null,
            date_result_type: "",
            height: 90,
            width: 200,
            filled_by: "SENDER",
            type: "FIELD",
            x: 235,
            y: 833.8046875,
            is_global_variable: false,
            relation_entity_id: e.relationship_id,
            self_entity_id: this.selectedEntity._id,
            filters: [
              {
                field: "self#related_to/1",
                operator: "=",
                value: "",
                query_type: "AND",
                data_type: "RELATION",
                data_source: "self_field",
                value_field: templateFieldKey,
                relation_entity_id: e.relationship_id,
              },
            ],
            entity_id: this.selectedEntity._id,
            visibility: true,
          };

          this.selectedEntityFields.push(field);
        });
      }
    },
    filterReadOnlyFields(fields) {
      let contentFields = [
        "HORIZONTAL_LINE",
        "DIVISION",
        "HTML_CONTENT",
        "SINGLE_LINE_CONTENT",
      ];
      return fields.filter((field) => {
        if (contentFields.indexOf(field.input_type) === -1) {
          return true;
        }
        return false;
      });
    },
    mapTemplatesFields(fields, data) {
      return fields.map((e) => {
        e.template_id = data._id;
        e.label = data.name + "-" + e.label;
        e.key = data._id + "#" + e.key;
        return e;
      });
    },
    getTemplateFields(data) {
      let fields = [];
      data = JSON.parse(JSON.stringify(data));
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        e.label = data.name + "-" + e.label;
        e.key = data._id + "#" + e.key;
        return e;
      });
      return fields;
    },
    setFieldData(fieldId) {
      let selectedEntityField = {};
      this.selectedEntityFieldData = {};
      selectedEntityField = this.selectedEntityFields.find(
        (field) => field.key == fieldId
      );
      //storing template id & field key separately
      if (fieldId && fieldId.includes("#")) {
        [
          this.field.global_variable_entity_field_template_id,
          this.field.global_variable_entity_select_type_field_key,
        ] = fieldId.split("#");
      }
      if (selectedEntityField == undefined) {
        this.actionFieldData.forEach((repeatableTemps) => {
          repeatableTemps.sections[0].fields.forEach((field) => {
            if (field._id == fieldId) {
              selectedEntityField = field;
            }
          });
        });
      }
      this.selectedEntityFieldData = selectedEntityField;
      if (selectedEntityField) {
        if (selectedEntityField?.input_type == "LIST") {
          this.field.list_data = selectedEntityField.list_data;
        }
        if (selectedEntityField?.input_type == "CHECKBOX_GROUP") {
          this.field.options = selectedEntityField.options;
          this.field.min_selection = selectedEntityField.min_selection;
          this.field.max_selection = selectedEntityField.max_selection;
          this.is_check_box_group = selectedEntityField.is_check_box_group;
        }
        if (selectedEntityField?.input_type == "RADIO_BUTTON_GROUP") {
          this.field.options = selectedEntityField.options;
        }
        if (
          selectedEntityField?.input_type == "TIME" &&
          selectedEntityField.is_fixed
        ) {
          this.field.is_fixed = true;
        }
        if (selectedEntityField?.input_type == "AGGREGATE_FUNCTION") {
          this.field.selected_fields = selectedEntityField.selected_fields;
        }
        if (selectedEntityField?.date_view_type) {
          this.field["date_view_type"] = selectedEntityField.date_view_type;
        }
        if (selectedEntityField?.inputType == "NUMBER") {
          this.field["max_value"] = selectedEntityField.max_value;
          this.field["min_value"] = selectedEntityField.min_value;
        }
        if (
          selectedEntityField.global_variable_id &&
          selectedEntityField.is_global_variable
        ) {
          this.field.global_variable_id =
            selectedEntityField.global_variable_id;
          this.field.is_global_variable = true;
        }
        if (selectedEntityField.inputType) {
          this.field.inputType =
            selectedEntityField.inputType == "SINGLE_LINE_TEXT"
              ? selectedEntityField.input_type
              : selectedEntityField.inputType;
        }

        if (
          selectedEntityField.entity_id &&
          selectedEntityField.inputType == "ENTITY"
        ) {
          this.field.entity_id = selectedEntityField.entity_id;
        }
        if (selectedEntityField.inputType == "RANDOM_TEXT") {
          this.field.selected_preferences =
            selectedEntityField.selected_preferences;
          this.field.random_text_length =
            selectedEntityField.random_text_length;
          //this.field.allow_to_regenerate = selectedEntityField.allow_to_regenerate;
          this.field.allow_to_regenerate = false;
        }
        if (selectedEntityField?.auto_increment_prefix) {
          this.field.auto_increment_prefix =
            selectedEntityField.auto_increment_prefix;
        }
        if (selectedEntityField?.auto_increment_starting_number) {
          this.field.auto_increment_starting_number =
            selectedEntityField.auto_increment_starting_number;
        }
        if (
          selectedEntityField?.data_table_columns &&
          selectedEntityField?.inputType == "DATA_TABLE"
        ) {
          if (this.fieldsData?.[this.index]?.["data_table_columns"]) {
            this.fieldsData[this.index]["data_table_columns"] =
              selectedEntityField.data_table_columns;
          }
          this.field["data_table_columns"] =
            selectedEntityField.data_table_columns;
        }
        if (
          (selectedEntityField?.input_type == "WEEKDAYS" ||
            selectedEntityField?.inputType == "WEEKDAYS") &&
          selectedEntityField?.allow_multiple
        ) {
          this.field.allow_multiple = true;
        }
        if (selectedEntityField?.result_type) {
          this.field["result_type"] = selectedEntityField.result_type;
        }
        this.field["width"] = selectedEntityField.width;
        this.field["height"] = selectedEntityField.height;
        this.field.validations = selectedEntityField.validations;
      }
      this.field.selected_enity_field_data = this.selectedEntityFieldData;
      if (selectedEntityField && selectedEntityField.is_relational) {
        this.field.entity_id = selectedEntityField.relation_entity_id;
        this.field.relationship_entity_id = selectedEntityField.self_entity_id;
      } else {
        delete this.field.relationship_entity_id;
      }

      if (selectedEntityField?.do_not_apply) {
        this.field.do_not_apply = selectedEntityField.do_not_apply;
      }
      if (this.parent_key) {
        this.field.global_variable_entity_parent = this.parent_key;
      }
      if (selectedEntityField?.date_view_type) {
        this.field.date_view_type = selectedEntityField.date_view_type;
      }
      if(selectedEntityField?.list_data){
        this.field.list_data = selectedEntityField.list_data
      }
    },
    checkData() {
      if (this.field.global_variable_entity_field) {
        this.setEntityFields(this.field.relationship_key, true);
        // if (
        //   this.field.input_type == "GLOBAL_VARIABLE" &&
        //   this.field.global_variable_entity_field
        // ) {
        //   this.setFieldData(this.field.global_variable_entity_field);
        // }

        // if (
        //   this.field.input_type == "ENTITY_VARIABLE" &&
        //   this.field.entity_id
        // ) {
        //   this.setFieldData(this.field.global_variable_entity_field);
        // }
      }
      // this.entitiesData.map((entity) => {
      //   entity.sections.map((section) => {
      //     if (section.fields && section.fields.length) {
      //       section.fields.map((element) => {
      //         if (element.input_type == "ACTION_BUTTON") {
      //           let fieldIndex = this.actionFieldData.findIndex(
      //             (e) => e._id == entity.action_button_repeating_template
      //           );
      //           entity["template_fields"] = this.actionFieldData[fieldIndex];
      //           entity["repeat_template_id"] =
      //             entity.action_button_repeating_template;
      //           if (
      //             this.actionFieldData[fieldIndex] &&
      //             this.actionFieldData[fieldIndex].primary_fields
      //           ) {
      //             let primaryFieldIndex = this.actionFieldData[
      //               fieldIndex
      //             ].primary_fields.findIndex(
      //               (e) => e._id == this.data.global_variable_entity_field
      //             );
      //             entity["repeatable_primary_field"] =
      //               primaryFieldIndex >= 0 ? true : false;
      //           } else {
      //             entity["repeatable_primary_field"] = false;
      //           }
      //         }
      //       });
      //     }
      //   });
      // });
    },
    onSelectParentData(key) {
      console.log("Selected Variable Key : ", key);
      console.log(
        "Selected Variable : ",
        this.field.global_variable_entity_parent
      );
    },
    async getFieldData(templateId) {
      try {
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          templateId
        );
        if (this.getSingleCompanyTemplate) {
          this.actionFieldData.push(this.getSingleCompanyTemplate);
          return this.getSingleCompanyTemplate;
        }
      } catch (error) {
        console.log("getFieldData", error);
      }
    },
    setFieldType() {
      if (
        this.selectedEntityFieldData.input_type == "SELECT" ||
        this.selectedEntityFieldData.input_type == "MULTI_SELECT"
      ) {
        if (this.field.global_variable_entity_select_type == "single") {
          this.field.selected_enity_field_data.input_type = "SELECT";
          this.selectedEntityFieldData.input_type = "SELECT";
        }
        if (this.field.global_variable_entity_select_type == "multiple") {
          this.field.selected_enity_field_data.input_type = "MULTI_SELECT";
          this.selectedEntityFieldData.input_type = "MULTI_SELECT";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.config-box {
  display: grid !important;
  grid-template-columns: 25% 25% 25% 25% !important;
  grid-column-gap: 5px;

  p {
    font-weight: bold;
  }
}

.config-radio {
  display: grid !important;
  grid-template-columns: 20% 40% 10% 10% !important;
  grid-column-gap: 5px;
}
.el-checkbox {
  margin-left: 80px;
}
</style>
